import { configureStore } from '@reduxjs/toolkit';
import cartReducer, { getTotal } from './cart/cartSlice';
import { productsApi } from './cart/productApi';
import authReducer from './auth/authSlice';
export const store = configureStore({
  reducer: {
    cart: cartReducer,
    auth: authReducer,
    [productsApi.reducerPath]: productsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productsApi.middleware),
});
store.dispatch(getTotal());

export default store;
