import { useDispatch } from 'react-redux';
import { useGetAllProductsQuery } from '../../redux/cart/productApi';
import { addToCart } from '../../redux/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function Shop() {
  const { data, error, isLoading } = useGetAllProductsQuery();

  // const { cartItems } = useSelector((store) => console.log(store));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate('/cart');
  };

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>An Error has occurred</p>
      ) : (
        <>
          {/* Advertisments */}
          <section id='page-header'>
            <h2>#homeautomation</h2>
            <p>Save more with coupons & up to 40% off!</p>
          </section>
          <section id='product1' class='section-p1'>
            <div class='pro-container'>
              {data?.product.map((product) => (
                <div class='pro' key={Math.random()}>
                  <Link to={`/product_details/${product.name}`}>
                    <img src={product.imageUrl} alt='' />
                  </Link>

                  <div class='desc'>
                    <span>{product.description}</span>
                    <h5>{product.name.split('-').join(' ')}</h5>
                    <div class='star'>
                      <i class='fas fa-star'></i>
                      <i class='fas fa-star'></i>
                      <i class='fas fa-star'></i>
                      <i class='fas fa-star'></i>
                      <i class='fas fa-star'></i>
                    </div>
                    <h4>{product.price}</h4>
                  </div>
                  <button onClick={() => handleAddToCart(product)}>
                    <i class='fas fa-shopping-cart cart'></i>
                  </button>
                </div>
              ))}
            </div>
          </section>
          <section id='pagination' class='section-p1'>
            <a href='#'>1</a>
            <a href='#'>2</a>
            <a href='#'>
              <i class='fas fa-long-arrow-alt-right'></i>
            </a>
          </section>
        </>
      )}
    </div>
  );
}

// export default Shop;
