import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

// const API_URL = process.env.REACT_APP_API_URL + '/api/';
const API_URL = '/api/';

const register = async (userData) => {
  const response = await axios.post(API_URL + 'register_user', userData);
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }
  return response.data;
};

const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData);
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }
  return response.data;
};
const logout = () => {
  localStorage.removeItem('user');
};

const loadUser = () => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
};

const authService = { register, login, logout, loadUser };

export default authService;
