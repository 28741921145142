import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetAllProductsQuery } from '../../redux/cart/productApi';
import { addToCart } from '../../redux/cart/cartSlice';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const { data, error, isLoading } = useGetAllProductsQuery();

  // const { cartItems } = useSelector((store) => console.log(store));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate('/cart');
  };

  return (
    <div>
      {/* Section 1 */}
      <section id='banner'>
        <h4>OFFER - 20% OFF</h4>
        <h2>Super value deals</h2>
        <h1>On all products</h1>
        <p>Save more with coupons & up to 40% off!</p>
        <button>Shop Now</button>
      </section>

      {/* Section2 */}
      <section id='feature' class='section-p1'>
        <div class='fe-box'>
          <img src='img/features/shipping.jpg' alt='' />
          <h6>Free Shipping</h6>
        </div>

        <div class='fe-box'>
          <img src='img/features/online-order.jpg' alt='' />
          <h6>Online Order</h6>
        </div>

        <div class='fe-box'>
          <img src='img/features/save-money.webp' alt='' />
          <h6>Save Money</h6>
        </div>

        <div class='fe-box'>
          <img src='img/features/promotion.png' alt='' />
          <h6>Promotion</h6>
        </div>

        <div class='fe-box'>
          <img src='img/features/happy-sell.webp' alt='' />
          <h6>Happy Sell</h6>
        </div>

        <div class='fe-box'>
          <img src='img/features/support.jpg' alt='' />
          <h6>Support</h6>
        </div>
      </section>

      {/* Section3       */}
      <section id='product1' class='section-p1'>
        <h2>Featured Products Edited</h2>
        <p>Electronics New Model Design</p>
        <div class='pro-container'>
          {data?.product.slice(0, 8).map((product) => (
            <div class='pro' key={Math.random()}>
              <img src={product.imageUrl} alt='' />
              <div class='desc'>
                <span>{product.description}</span>
                <h5>{product.name}</h5>
                <div class='star'>
                  <i class='fas fa-star'></i>
                  <i class='fas fa-star'></i>
                  <i class='fas fa-star'></i>
                  <i class='fas fa-star'></i>
                  <i class='fas fa-star'></i>
                </div>
                <h4>{product.price}</h4>
              </div>
              <button onClick={() => handleAddToCart(product)}>
                <i class='fas fa-shopping-cart cart'></i>
              </button>
            </div>
          ))}
        </div>
      </section>

      {/* Section4 */}
      <section id='banner2' class='section-m1'>
        <h4>Repair Services</h4>
        <h2>
          Up to <span>40% Off</span> - Every Item
        </h2>
        <button class='normal'>Explore More</button>
      </section>

      {/* Section5 */}
      <section id='product1' class='section-p1'>
        <h2>New Arrivals</h2>
        <p>Electronics New Model and Design</p>
        <div class='pro-container'>
          {data?.product.slice(0, 8).map((product) => (
            <div class='pro' key={Math.random()}>
              <img src={product.imageUrl} alt='' />
              <div class='desc'>
                <span>{product.description}</span>
                <h5>{product.name}</h5>
                <div class='star'>
                  <i class='fas fa-star'></i>
                  <i class='fas fa-star'></i>
                  <i class='fas fa-star'></i>
                  <i class='fas fa-star'></i>
                  <i class='fas fa-star'></i>
                </div>
                <h4>{product.price}</h4>
              </div>
              <button onClick={() => handleAddToCart(product)}>
                <i class='fas fa-shopping-cart cart'></i>
              </button>
            </div>
          ))}
        </div>
      </section>

      {/* Section6 */}
      <section id='sm-banner' class='section-p1'>
        <div class='banner-box'>
          <h4>crazy deals</h4>
          <h2>buy 1 get 1 free</h2>
          <span>The best quality product is on sale at eneeds</span>
          <button class='white'>Learn More</button>
        </div>

        <div class='banner-box banner-box2'>
          <h4>crazy deals</h4>
          <h2>buy 1 get 1 free</h2>
          <span>The best quality product is on sale at eneeds</span>
          <button class='white'>Collection</button>
        </div>
      </section>

      {/* Section7 */}
      <section id='banner3'>
        <div class='banner-box'>
          <h2>SEASONAL SALE</h2>
          <h3>Winter Collection - 50% OFF</h3>
        </div>

        <div class='banner-box banner-box2'>
          <h2>SEASONAL SALE</h2>
          <h3>Winter Collection - 50% OFF</h3>
        </div>

        <div class='banner-box banner-box3'>
          <h2>SEASONAL SALE</h2>
          <h3>Winter Collection - 50% OFF</h3>
        </div>
      </section>

      <script src='script.js'></script>
    </div>
  );
};
export default Landing;
