import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const navigation = [
    { name: 'Home', to: '/' },
    { name: 'Shop', to: '/shops' },
    { name: 'Blog', to: '/blog' },
    { name: 'About', to: '/about' },
    { name: 'Contact', to: '/contact' },
  ];
  return (
    <section id='header'>
      <a href='#'>
        <img src='img/full-name.png' class='logo' alt='' />
      </a>

      <div>
        <ul id='navbar'>
          {navigation.map((nav) => {
            return (
              <li key={nav.name}>
                <NavLink to={nav.to}>{nav.name}</NavLink>
              </li>
            );
          })}
          <li id='lg-bag'>
            <NavLink to='/cart'>
              <i class='fa fa-shopping-cart' aria-hidden='true'></i>
            </NavLink>
          </li>
          <a href='#' id='close'>
            <i class='fa fa-times'></i>
          </a>
        </ul>
      </div>
      <div id='mobile'>
        <a href='cart.html'>
          <i class='fa fa-shopping-cart' aria-hidden='true'></i>
        </a>
        <i id='bar' class='fas fa-outdent'></i>
      </div>
    </section>
  );
};
export default Navbar;
