import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  cartItems: localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : [],
  totalQty: 0,
  subTotal: 0,
  // JSON.parse(localStorage.getItem('cartItems')).map(
  //   (item) => item.cartQuantity * item.price
  // ),
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const productIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
        // (item) => console.log(state.cartItems.id)
      );
      if (productIndex >= 0) {
        state.cartItems[productIndex].cartQuantity += 1;
        toast.info(
          `Total of ${state.cartItems[productIndex].cartQuantity} ${action.payload.name} in cart now`,
          { position: 'bottom-left' }
        );
      } else {
        const tempProductList = { ...action.payload, cartQuantity: 1 };
        // console.log(tempProductList);
        state.cartItems.push(tempProductList);
        toast.success(`${action.payload.name} Added to Cart`, {
          position: 'bottom-left',
        });
      }
      // state.cartItems.push(action.payload);
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      console.log({ ...action.payload });
    },

    removeFromCart(state, action) {
      const updateCart = state.cartItems.filter(
        (item) => item._id !== action.payload._id
      );
      state.cartItems = updateCart;
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      toast.error(`${action.payload.name} removed from Cart`, {
        position: 'bottom-left',
      });
    },

    decereseItem(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
        toast.info(
          `${action.payload.name} deceresed to ${state.cartItems[itemIndex].cartQuantity}`,
          { position: 'bottom-left' }
        );
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const updateCart = state.cartItems.filter(
          (item) => item._id !== action.payload._id
        );
        state.cartItems = updateCart;
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
        toast.error(`${action.payload.name} removed from Cart`, {
          position: 'bottom-left',
        });
      }
    },

    incereseItem(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (state.cartItems[itemIndex].cartQuantity) {
        state.cartItems[itemIndex].cartQuantity += 1;
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
        toast.info(
          `${action.payload.name} inceresed to ${state.cartItems[itemIndex].cartQuantity}`,
          { position: 'bottom-left' }
        );
      }
    },

    clearCart(state) {
      state.cartItems = [];
      toast.error('Cart is Empty', { position: 'bottom-left' });
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },

    getTotal(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const itemTotal = price * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;
          return cartTotal;
        },
        {
          total: 0,
          quantiy: 0,
        }
      );
      state.totalQty = quantity;
      state.subTotal = total;
    },
  },
});
export const {
  addToCart,
  removeFromCart,
  decereseItem,
  incereseItem,
  clearCart,
  getTotal,
} = cartSlice.actions;
export default cartSlice.reducer;
