import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
var API_URL = '';
if (
  process.env.REACT_APP_DEV === 'true' ||
  process.env.NODE_ENV === 'development'
) {
  API_URL = process.env.REACT_APP_API_URL + '/api/';
} else {
  API_URL = process.env.REACT_APP_API_PROD + '/api/';
}

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: () => 'product',
    }),
  }),
});

export const { useGetAllProductsQuery } = productsApi;
