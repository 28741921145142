import React from 'react';

const Blog = () => {
  return (
    <div>
      <section id='page-header' class='blog-header'>
        <h2>#readmore</h2>

        <p>Read all case studies anput our products! </p>
      </section>
      <section id='blog'>
        <div class='blog-box'>
          <div class='blog-img'>
            <img src='img/blog/double induction.jpg' alt='' />
          </div>

          <div class='blog-details'>
            <h4>Double-induction Cooker</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <a href='#'>CONTINUE READING</a>
          </div>
          <h1>13/01</h1>
        </div>

        <div class='blog-box'>
          <div class='blog-img'>
            <img src='img/blog/double induction.jpg' alt='' />
          </div>

          <div class='blog-details'>
            <h4>Double-induction Cooker</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <a href='#'>CONTINUE READING</a>
          </div>
          <h1>13/01</h1>
        </div>

        <div class='blog-box'>
          <div class='blog-img'>
            <img src='img/blog/double induction.jpg' alt='' />
          </div>

          <div class='blog-details'>
            <h4>Double-induction Cooker</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <a href='#'>CONTINUE READING</a>
          </div>
          <h1>13/01</h1>
        </div>

        <div class='blog-box'>
          <div class='blog-img'>
            <img src='img/blog/double induction.jpg' alt='' />
          </div>

          <div class='blog-details'>
            <h4>Double-induction Cooker</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <a href='#'>CONTINUE READING</a>
          </div>
          <h1>13/01</h1>
        </div>

        <div class='blog-box'>
          <div class='blog-img'>
            <img src='img/blog/double induction.jpg' alt='' />
          </div>

          <div class='blog-details'>
            <h4>Double-induction Cooker</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius est
              impedit eaque vitae voluptate reiciendis earum. Voluptas, facilis
              porro non impedit nam pariatur facere ipsa officiis molestias?
              Corporis, iure hic!
            </p>
            <a href='#'>CONTINUE READING</a>
          </div>
          <h1>13/01</h1>
        </div>
      </section>
      <section id='pagination' class='section-p1'>
        <a href='#'>1</a>
        <a href='#'>2</a>
        <a href='#'>
          <i class='fas fa-long-arrow-alt-right'></i>
        </a>
      </section>
    </div>
  );
};

export default Blog;
