import React from 'react';

const Footer = () => {
  return (
    <div>
      {/* Section8 */}
      <section id='newsletter' class='section-p1 section-m1'>
        <div class='newstext'>
          <h4>Sign Up For Newsletter Edited</h4>
          <p>
            Get E-mail updates about our latest shop and{' '}
            <span>special offers.</span>
          </p>
        </div>

        <div class='form'>
          <input type='text' placeholder='Enter your e-mail address' />
          <button class='normal'>Sign Up</button>
        </div>
      </section>
      {/* FOOTER */}
      <footer class='section-p1'>
        <div class='col first-col'>
          <img class='logo' src='img/logo/Eneeds-Complete.png' alt='logo' />
          <h4>Contact</h4>
          <p>
            <strong>Address:</strong> Thapathali-Height, Kathmandu, Nepal
          </p>
          <p>
            <strong>Phone:</strong> +977-01......
          </p>
          <p>
            <strong>Hours:</strong> 10:00 - 18:00, Sun - Sat
          </p>

          <div class='follow'>
            <h4>Follow us</h4>
            <div class='icon'>
              <i class='fab fa-facebook-f'></i>
              <i class='fab fa-twitter'></i>
              <i class='fab fa-instagram'></i>
              <i class='fab fa-pinterest-p'></i>
            </div>
          </div>
        </div>

        <div class='col'>
          <h4>About</h4>
          <a href='#'>About us</a>
          <a href='#'>Delivery Information</a>
          <a href='#'>privacy Policy</a>
          <a href='#'>Terms & Conditions</a>
          <a href='#'>Contact Us</a>
        </div>

        <div class='col'>
          <h4>My Account</h4>
          <a href='#'>Sign In</a>
          <a href='#'>View Cart</a>
          <a href='#'>My Wishlist</a>
          <a href='#'>Track My Order</a>
          <a href='#'>Help</a>
        </div>

        <div class='col install'>
          <h4>Install App</h4>
          <p>From App Store of Google Play</p>
          <div class='row'>
            <img src='img/pay/apple.png' alt='app-store' />
            <img src='img/pay/google.png' alt='play-store' />
          </div>
          {/* <p>Secured Payment Gateways</p>
              <img src="img/pay/" alt="Add payment gateway here"> */}
        </div>

        <div class='copyright'>
          <p>&copy; 2022, E-needs Nepal Pvt. Ltd.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
