import React from 'react';

const Contact = () => {
  return (
    <div>
      <section id='page-header' class='about-header'>
        <h2>#let's_talk</h2>

        <p>LEAVE A MESSAGE, We would love to hear from you!</p>
      </section>

      <section id='contact-details' class='section-p1'>
        <div class='details'>
          <span>GET IN TOUCH</span>
          <h2>Visit our agency location or contact us today.</h2>
          <h3>Head Office</h3>
          <div>
            <li>
              <i class='fa fa-map'></i>
              <p>Thapathali-height, Kathmandu, Nepal</p>
            </li>

            <li>
              <i class='fa fa-envelope'></i>
              <p>chaulagainaaditya@gmail.com</p>
            </li>

            <li>
              <i class='fa fa-phone-alt'></i>
              <p>(contact-number)</p>
            </li>

            <li>
              <i class='fa fa-clock'></i>
              <p>Sunday to Sunday: 9:00 am to 15:00 pm</p>
            </li>
          </div>
        </div>

        <div class='map'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14131.161071674307!2d85.31214078669437!3d27.69287642611217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1923f2fec9bb%3A0xf009e78cb11061e5!2sE-Needs%20Nepal!5e0!3m2!1sen!2snp!4v1654957624342!5m2!1sen!2snp'
            width='600'
            height='450'
            frameborder='0'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </section>
      <section id='form-details'>
        <form action=''>
          <span>LEAVE A MESSAGE</span>
          <h2>We would love to hear from you</h2>
          <input type='text' placeholder='Your Name' />
          <input type='text' placeholder='E-mail' />
          <input type='text' placeholder='Subject' />
          <textarea
            name=''
            id=''
            cols='30'
            rows='10'
            placeholder='Your Message'
          ></textarea>
          <button class='normal'>Submit</button>
        </form>

        <div class='people'>
          <div>
            <img src='img/people/male.jpg' alt='' />
            <p>
              <span>Rameshwor Chaulagain</span> CEO <br /> Phone:
              +977-9876543210 <br />
              Email: raamesh2031@gmail.com
            </p>
          </div>

          <div>
            <img src='img/people/female.jpg' alt='' />
            <p>
              <span>Rameshwor Chaulagain</span> CEO <br /> Phone:
              +977-9876543210 <br />
              Email: raamesh2031@gmail.com
            </p>
          </div>

          <div>
            <img src='img/people/male.jpg' alt='' />
            <p>
              <span>Rameshwor Chaulagain</span> CEO <br /> Phone:
              +977-9876543210 <br />
              Email: raamesh2031@gmail.com
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
