import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  removeFromCart,
  decereseItem,
  incereseItem,
  clearCart,
  getTotal,
} from '../../redux/cart/cartSlice';

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  useEffect(() => {
    dispatch(getTotal());
  }, [cart]);
  const dispatch = useDispatch();

  const handleRemoveFromCart = (cartItem) => {
    dispatch(removeFromCart(cartItem));
  };
  const handleDecereseFromCart = (cartItem) => {
    dispatch(decereseItem(cartItem));
  };
  const handleIncereseInCart = (cartItem) => {
    dispatch(incereseItem(cartItem));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  return (
    <div>
      {cart.cartItems.length === 0 ? (
        <div>Your Cart is Empty</div>
      ) : (
        <section id='cart' class='section-p1'>
          <table width='100%'>
            <thead>
              <tr>
                <td>Remove</td>
                <td>Images</td>
                <td>Product</td>
                <td>Price</td>
                <td>Quantity</td>
                <td>Subtotal</td>
              </tr>
            </thead>
            {cart.cartItems?.map((cartItem) => (
              <tbody key={cartItem.id}>
                <tr>
                  <td>
                    <div>
                      <button
                        id='remove'
                        onClick={() => handleRemoveFromCart(cartItem)}
                      >
                        {/* <i class='far fa-times-circle'></i> */}
                        Remove
                      </button>
                    </div>
                  </td>
                  <td>
                    <img src={cartItem.imageUrl} alt='' />
                  </td>
                  <td>{cartItem.name}</td>
                  <td>Rs.{cartItem.price}</td>
                  <td>
                    <button
                      onClick={() => {
                        handleDecereseFromCart(cartItem);
                      }}
                    >
                      -
                    </button>

                    <div>{cartItem.cartQuantity}</div>
                    <button
                      onClick={() => {
                        handleIncereseInCart(cartItem);
                      }}
                    >
                      +
                    </button>
                  </td>
                  <td>Rs.{cartItem.cartQuantity * cartItem.price}</td>
                </tr>
              </tbody>
            ))}
          </table>
          <button class='normal'>
            <Link to='/shops'>Continue Shopping</Link>
          </button>
          <button
            onClick={() => {
              handleClearCart();
            }}
          >
            Clear Cart
          </button>
        </section>
      )}

      <section id='cart-add' class='section-p1'>
        <div id='coupon'>
          <h3>Apply Coupon</h3>
          <div>
            <input type='text' placeholder='Enter Your Coupon' />
            <button class='normal'>Apply</button>
          </div>
        </div>

        <div id='subtotal'>
          <h3>Cart Totals</h3>
          <table>
            <tr>
              <td>Cart Subtotal</td>
              <td>Rs.{cart.subTotal}</td>
            </tr>
            <tr>
              <td>Shipping</td>
              <td>Calculate at checkout</td>
            </tr>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td>Rs.{cart.subTotal}</td>
            </tr>
          </table>
          <button class='normal'>Proceed to checkout</button> <br /> <br />
        </div>
      </section>
    </div>
  );
};

export default Cart;
