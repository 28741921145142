import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/cart/cartSlice';

export const ProductDetails = () => {
  const [product, setData] = useState([]);
  var API_URL = '';
  if (
    process.env.REACT_APP_DEV === 'true' ||
    process.env.NODE_ENV === 'development'
  ) {
    API_URL = process.env.REACT_APP_API_URL + '/api/product/';
  } else {
    API_URL = process.env.REACT_APP_API_PROD + '/api/product/';
  }
  const { product_name } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + product_name);
        const item = response.data;
        setData(item.product[0]);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [product_name]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  if (isLoading) {
    return isLoading;
  }

  const onChange = (e) => {
    setData({ ...product, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(addToCart(product));
  };

  return (
    <div>
      {/* {data?.product.map((product) => ( */}
      <form
        onSubmit={(e) => {
          onSubmit(e);
        }}
      >
        <section id='prodetails' class='section-p1'>
          <div class='single-pro-image' key={product.id}>
            <img src={product.imageUrl} width='100%' id='MainImg' alt='' />
            {product?.imageUrl.map((img) => (
              <div class='small-img-group' key={Math.random()}>
                <div class='small-img-col'>
                  <img src={img} width='100%' class='small-img' alt='' />
                </div>
              </div>
            ))}
          </div>

          <div class='single-pro-details'>
            <h6>Home / Watches</h6>
            <h4>{product.name.split('-').join(' ')}</h4>
            <h2>Rs.{product.price}</h2>
            {product.color != '' ? (
              <>
                <select onChange={(e) => onChange(e)}>
                  <option>Select Color</option>
                  {product?.color.map((col) => (
                    <>
                      <option>{col}</option>
                    </>
                  ))}
                </select>
              </>
            ) : (
              <></>
            )}
            <div>
              {product.color != '' ? (
                <>
                  {product?.color.map((col) => (
                    <>
                      <Link to={`/product_details/${product.name}-${col}`}>
                        {col}
                      </Link>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <input type='number' value='1' /> */}
            <button class='normal'>Add To Cart</button>
            <h4>Product Details</h4>
            <span>{product.description}</span>
          </div>
        </section>
      </form>
      {/* ))} */}
      <section id='product1' class='section-p1'>
        <h2>Featured Products</h2>
        <p>Electronics New Model and Design</p>
        <div class='pro-container'>
          <div class='pro'>
            <img src='img/products/watch.jfif' alt='' />
            <div class='desc'>
              <span>Apple</span>
              <h5>Apple Watch</h5>
              <div class='star'>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
              </div>
              <h4>Rs.25,000</h4>
            </div>
            <a href='#'>
              <i class='fas fa-shopping-cart cart'></i>
            </a>
          </div>

          <div class='pro'>
            <img src='img/products/camera.jfif' alt='' />
            <div class='desc'>
              <span>Apple</span>
              <h5>Apple Watch</h5>
              <div class='star'>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
              </div>
              <h4>Rs.25,000</h4>
            </div>
            <a href='#'>
              <i class='fas fa-shopping-cart cart'></i>
            </a>
          </div>

          <div class='pro'>
            <img src='img/products/headphone.jpg' alt='' />
            <div class='desc'>
              <span>Apple</span>
              <h5>Apple Watch</h5>
              <div class='star'>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
              </div>
              <h4>Rs.25,000</h4>
            </div>
            <a href='#'>
              <i class='fas fa-shopping-cart cart'></i>
            </a>
          </div>

          <div class='pro'>
            <img src='img/products/headphone.jpg' alt='' />
            <div class='desc'>
              <span>Apple</span>
              <h5>Apple Watch</h5>
              <div class='star'>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
                <i class='fas fa-star'></i>
              </div>
              <h4>Rs.25,000</h4>
            </div>
            <a href='#'>
              <i class='fas fa-shopping-cart cart'></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetails;
