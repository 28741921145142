// import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Shop from './components/layout/Shop';
import Blog from './components/layout/Blog';
import Contact from './components/layout/Contact';
// import ShopContainer from './components/layout/ShopContainer';
import Cart from './components/layout/Cart';
import Footer from './components/layout/Footer';
import ProductDetails from './components/layout/ProductDetails';

import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <ToastContainer autoClose={1000} />
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Landing />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/shops' element={<Shop />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/cart' element={<Cart />} />
        <Route
          path='/product_details/:product_name'
          element={<ProductDetails />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
